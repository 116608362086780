// returns appropriate debug/min script urls
$LAB.jsUrl = function (scriptName) {
    var url = '/b/build/' + $LAB.APP_VERSION + '/' + $LAB.LOCALE_NAME + '/js/';
    if ($LAB.APP_VERSION == 'debug') {
        url += scriptName + '.debug.js';
    } else {
        url += scriptName + '.min.js';
    }
    return url;
};
// returns appropriate stylesheet urls
$LAB.cssUrl = function (styleSheetName) {
    return '/b/build/' + $LAB.APP_VERSION + '/' + $LAB.LOCALE_NAME +  '/css/' + styleSheetName + '.css';
};
// returns url for img directory
$LAB.imgDirUrl = function () {
    return '/b/build/' + $LAB.APP_VERSION + '/img/';
};